<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Profession</h1>
      </div>
    </div>
    <div class="card theme-card">
      <div class="card-body">
        <alert v-if="professions.isLoading" />
        <form @submit="addProfession" v-if="!professions.isLoading" validate>
          <select-control
            v-model="profession"
            :options="filteredProfession"
            :required="true"
            labelClass="col-md-2 col-12"
            >Profession</select-control
          >
          <input-control
            v-model="collegeNumber"
            type="horizontal"
            v-if="regulated"
            :required="true"
            >College Number</input-control
          >
          <div class="form-group row align-items-center">
            <label class="col-4 col-md-2 col-form-label mr-3">Rate Type</label>
            <div class="custom-control custom-radio mr-4">
              <input
                type="radio"
                id="hour"
                value="hour"
                v-model="rateType"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="hour">Hourly</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="flat"
                value="flat"
                v-model="rateType"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="flat">Flat</label>
            </div>
          </div>
          <input-control
            control="number"
            v-model="rate"
            type="horizontal"
            step="0.01"
            min="0"
            :required="true"
            >Rate</input-control
          >
          <save :saving="professions.isSaving">Add Profession</save>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  computed: {
    ...mapState({
      professions: (state) => state.professions,
      provider: (state) => state.providers.provider,
    }),
    regulated: function () {
      let e = this.professions.data.find((p) => {
        if (p.id == this.profession) {
          return p;
        }
      });
      if (e) {
        if (!e.isRegulated) {
          this.clearCollegeNumber();
        }
        return e.isRegulated;
      }
      return false;
    },
    filteredProfession: function () {
      const array = [];
      this.professions.data.filter((p) => {
        array.push({ label: p.name, value: p.id });
      });
      return array;
    },
  },
  data() {
    return {
      profession: null,
      collegeNumber: null,
      rate: 0,
      rateType: "hour",
    };
  },
  mounted() {
    this.getAllProfessions();
  },
  methods: {
    ...mapActions({
      getAllProfessions: "professions/all",
      attachProfession: "professions/attachProfessionToUser",
    }),
    clearCollegeNumber: function () {
      this.collegeNumber = null;
    },
    addProfession: function (e) {
      e.preventDefault();
      this.attachProfession({
        profession: this.profession,
        collegeNumber: this.collegeNumber,
        user: this.provider.user_id,
        rate: this.rate,
        rateType: this.rateType,
      })
        .then(() => {
          this.$router.push({
            name: "providers.profession",
            params: { id: this.$route.params.id },
          });
        })

        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.saving = false;
        });
    },
  },
};
</script>
